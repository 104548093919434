
const constant = {
    websiteName:'BetBazzar',
    baseUrl: 'https://baazikart.com/',
    whatsAppNumber:'+918888880035',
    instagramUrl:'https://instagram.com/betbazzar247?igshid=YmMyMTA2M2Y=',
    telegramUrl:'https://t.me/BetBazzar',
    youTubeUrl:'',
    apkUrl:'https://betbazzar.com/android/BetBazzar.apk',
    transactionType: {
        depositWallet: 'DepositWalletRequest',
        withdrawWallet: 'WithdrawWalletRequest',
        depositId: 'DepositIdRequest',
        withdrawId: 'WithdrawIdRequest',
        createId: 'CreateIdRequest',
        changeIdPasswordRequest: 'ChangeIdPasswordRequest'
    },
    transactionFor: {
        idDeposit: 'idDeposit',
        walletDeposit: 'walletDeposit',
        createIdDeposit: 'createIdDeposit',
        changePasswordRequest: 'changePasswordRequest'
    },
    accountType: {
        savings: 'savings',
        current: 'current'
    }
}

export default constant;